<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading
            :active="isActive"
            spinner="bar-fade-scale"
            color="#428BCA"
          ></vue-element-loading>
          <input-form-text
            textInput="Nome"
            :required="true"
            v-model="form.title"
            :value="form.title"
          ></input-form-text>
          <div class="form-group">
            <select-form
              required
              v-model="form.regioes_id"
              name="Regiões"
              url="/regioes/all"
              id="id"
              titulo="title"
              layout="true"
            ></select-form>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right"></label>
            <div class="col-sm-5">
              <input
                ref="file"
                type="file"
                @change="previewFiles"
                class="custom-file-input"
              />
            </div>
          </div>
          <div class="form-group">
            <label
              class="col-sm-3 control-label no-padding-right"
              for="observacao"
            >
              Descrição</label
            >
            <div class="col-sm-8">
              <textarea
                class="form-control autosize"
                v-model="form.gpx"
                placeholder="Descrição"
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <footer-button :disabled="isActive"></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import SelectForm from '@/components/form/SelectForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { sucesso, erro } from '@/components/Message'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading,
    SelectForm
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Mapas',
      form: {
        title: '',
        regioes_id: '',
        gpx: ''
      },
      isActive: false
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    previewFiles() {
      let reader = new FileReader()
      reader.onload = event => {
        this.form.gpx = event.target.result
      }
      reader.readAsText(this.$refs.file.files[0])
      this.form.title = this.$refs.file.files[0].name
        .replace('.gpx', '')
        .split('_')
        .join(' ')
    },
    async gravar() {
      this.isActive = true
      const body = this.form
      if (this.$route.params.id) {
        await Services.put(`/mapas/${this.$route.params.id}`, body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/sistema/mapas')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      } else {
        await Services.post('/mapas', body)
          .then(() => {
            sucesso('Atualizado...')
            this.form.title = ''
            this.form.gpx = ''
            //this.$router.push('/sistema/mapas')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      }
      this.isActive = false
    },
    async getDados() {
      const dados = await Services.get(`/mapas/${this.$route.params.id}`).catch(
        response => {
          console.log('error: ' + response)
          return (this.isActive = false)
        }
      )
      this.form.title = dados.title
      this.form.regioes_id = dados.regioes_id
      this.form.gpx = dados.gpx
      this.isActive = false
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
